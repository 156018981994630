@tailwind base;
a {
  @apply tw-text-blue-400;
}

@tailwind components;
@tailwind utilities;

.border-bottom {
  @apply tw-border-guide-grey-80 tw-border-b-2 tw-border-solid;
}

.border-top {
  @apply tw-border-guide-grey-80 tw-border-t-2 tw-border-solid;
  border-top-style: solid; /* For some reason, this is necessary, even if it was applied above. */
}


.container {
    box-sizing: border-box;
    @media (max-width: 921px) {
        /* Overwrite container for 100vw at this breakpoint instead of 100% with which would break the header layout. */
        width: 100vw;
    }
    @media (min-width: 921.6px) and (max-width: 1190px) {
        /* Overwrite container for 100vw at this breakpoint instead of a fixed with which would break the header layout. */
        width: 100vw;
    }
}


/* ecsm 2024 */

.ecsm-2024 .outer-wrapper {
  background: none;
}
.ecsm-2024 h1 {
  font-size: 2.5rem;
}
.ecsm-2024.template-homepage h2 {
  font-size: 2rem;
}
.ecsm-2024 h3 {
  font-size: 1.75rem;
}
.ecsm-2024 h4 {
  font-size: 1.5rem;
}
.ecsm-2024 h5 {
  font-size: 1.25rem;
}
.ecsm-2024 h6 {
  font-size: 1rem;
}
.ecsm-2024 .heading-cybersecmonth {
}
.ecsm-2024 .heading-organise-event {
}

.ecsm-2024 a {
}
.ecsm-2024 .top-siteactions {
  /* @apply tw-bg-blue-800; */ /* cannot be found ./ */
  background-color: #194bab;
  position: relative;
}
.ecsm-2024 .top-siteactions a {
  @apply tw-text-white tw-border-r tw-border-white;
  border-left: none; /* overwriting Plone custom.css */
}
.ecsm-2024 .top-siteactions a:last-child {
  border: none;
}
.ecsm-2024 .top-siteactions .tw-flex {
    width: 100%;
    padding-right: 1.6rem;
}

.ecsm-2024 #content-header {
  background-image: linear-gradient(to right, #33998b, #077c96 50%, #259090);
}

.ecsm-2024 #portal-logo {
  img {
    height: 100%;
    width: auto;
  }
}

.ecsm-2024-gradient-background {
  background-image: linear-gradient(to right, #33998b, #077c96 50%, #259090);
}
.ecsm-2024.template-awards .ecsm-2024-gradient-background a,
.ecsm-2024.template-awards .ecsm-2024-gradient-background a:hover,
.ecsm-2024.template-awards .ecsm-2024-gradient-background a:visited {
  color: white;
  text-decoration: underline;
}

.ecsm-2024 .tw-text-black select {
  @apply tw-bg-enisa-teal;
  @apply tw-text-white;
}
.ecsm-2024 article#content {
  margin-top: 4rem;
}
.ecsm-2024 .color-inherit,
.ecsm-2024 .color-inherit a {
  color: inherit;
}

.ecsm-2024 .figcaption-gap figcaption {
  @apply tw-mt-2;
}

.ecsm-2024 .tw-text-black {
  @apply tw-text-black;
}
.ecsm-2024 .tw-text-white {
  @apply tw-text-white;
}

.ecsm-2024.template-homepage #portal-footer-wrapper {
  margin-top: -3rem;
}

#mainnavigation {
  background-color: rgba(255,255,255,0.7);
  @apply tw-flex tw-justify-end;
}

.plone-navbar-toggle {
  float: none !important;
  @apply tw-uppercase;
}
.plone-navbar-toggle .icon-bar::after {
  font-size: 1rem;
  @apply tw-font-bold tw-uppercase;
}
#portal-globalnav {
  margin: auto;
  float: none;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
}


.plone-nav li .opener + label::after {
    color: #0b3382;
    font-size: 0.75rem;
    padding: 0 0.5rem;
}
@media (max-width: 576px) {
    .plone-nav li .opener + label::after {
        padding: 0 2rem;
    }
    .plone-nav li .opener:checked + label::after {
        top: 1.2rem;
    }
}


.ecsm-2024 #portal-header .plone-nav li a {
  @apply tw-text-base; /* overwriting Plone sitenav.plone.less */
}
.ecsm-2024 #portal-header .plone-nav li {
  @apply tw-border-r tw-border-white;
  @media only screen and (max-width: 575px) {
    border: none;
  }
}
.ecsm-2024 #portal-header .plone-nav li:last-child {
  border: none;
}

.plone-navbar-nav > li {
  float: none !important;
  display: inline-block;
}
